.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.video-thumbnail{
  width: 100%;    
  width: 900px;
  margin: 0 auto;
  height: calc(900px / 1.9);
}

.videourl {
  background-image: url("../../Images/video-back.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% 80%;
}

.video-image {
  position: absolute;
  z-index: 0;
  left: 10px;
}

.play-button {
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #ffcc00, #e64656);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  padding-left: 10px;
  padding-top: 4px;
}


@media only screen and (max-width: 1350px) {
  .video-thumbnail{
    max-width: 70vw;
    height: calc(70vw / 1.9);
  }
}

@media only screen and (max-width: 1150px) {
  /* .video-thumbnail{
    max-width: 50vw;
    width: 100%;
  } */
}

@media only screen and (max-width: 786px) {
  .video-thumbnail{
    max-width: 80vw !important;
    width: 80vw;
    height: calc(80vw / 1.8);
  }
}

@media only screen and (max-width: 480px) {
  .video-thumbnail{
    max-width: 90vw !important;
    width: 90vw;
    height: calc(90vw / 1.5);
  }
}