.about{

}

.about-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  max-width: 1690px;
  margin: 0 auto;
  margin-top: 45px;
}

.about-img{
  width: 100%;
  flex-grow: 1;
}

.about-text-box{
  color: white;
}

.about-text-box h4{
  font-family: "ChronicaPro", sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 30px;
}

.about-text-box h2{
  font-family: "ChronicaPro", sans-serif;
  font-size: 68px;
  font-weight: bold;
  letter-spacing: 20;
  line-height: 72px;
}

.text-desc{
  text-align: left !important;
}

.about-text-box p{
  font-family: "ChronicaPro", sans-serif;
  font-size: 17px;
  font-style: normal;
  color: white;
  font-weight: 400;
  max-width: 535px;
  margin-top: 20px;
}

.about-btn{
  height: 2.5rem;
  border-radius: 0.375rem;
  background-image: linear-gradient(to right,var(--tw-gradient-stops));
  --tw-gradient-from: #FFCC00;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to, rgba(255, 204, 0, 0));
  --tw-gradient-to: #E64656;
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  font-family: Montserrat;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 600;
  line-height: .875rem;
  --tw-text-opacity: 1;
  color: rgba(0,0,0,var(--tw-text-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
  color: black  !important;
  width: max-content;
}

.about-btn:hover{
  background-image: linear-gradient(to left,var(--tw-gradient-stops));
  --tw-gradient-from: #FFCC00;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to, rgba(255, 204, 0, 0));
  --tw-gradient-to: #E64656;
  background-position: top;
}

.about-reverse{
  flex-direction: row-reverse !important;
}

.about-secondary{
  gap: 80px !important;
}

.about-img-box-secondary img{
  width: 90%;
}

.about-tertiary{
  margin-top: -50px;
}

@media only screen and (max-width: 1500px) {
  .about-container{
    /* background: orange; */
  }

  .about-container{
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .about-img-box {
    max-width: 50%;
    flex-shrink: 1;
  }

  .about-img-box img{
    width: 100%;
    flex-grow: 0;
  }

  .about-secondary{
    gap: 30px !important;
  }
}


@media only screen and (max-width: 1350px) {
  .about-tertiary{
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1150px) {
  .about-text-box p,
  .about-text-box h4{
    font-size: 15px;
  }

  .about-text-box h2{
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 1024px) {
  .about-container{
    flex-direction: column-reverse;
    margin: 50px 0;
  }

  .about-reverse{
    flex-direction: column-reverse !important;
  }
  

  .about-img-box,
  .about-img-box-secondary{
    max-width: 70%;
    flex-shrink: 1;
  }
}

@media only screen and (max-width: 786px) {
  .about-img-box,
  .about-img-box-secondary{
    max-width: 90%;
    flex-shrink: 1;
  }
}

@media only screen and (max-width: 550px) {
  .about-img-box,
  .about-img-box-secondary{
    max-width: 100%;
    flex-shrink: 1;
  }
}

@media only screen and (max-width: 520px) {
  .about-text-box h2{
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 1px;
  }
}
