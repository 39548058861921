.bitcoin-section {
  background-color: black;
  border-radius: 20px;
  max-width: 1300px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 226px;
  max-height: 226px;
  /* overflow: hidden; */
  margin: 0 auto;
  margin-top: 60px;
  width: 100%;
}

.bitcoin-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.bitcoin-left{
  display: flex;
  align-items: center;
}

.bitcoin-center{
  /* background-color: rebeccapurple; */
  width: 300px;
  height: 226px;
  /* position: relative; */
}

.bitcoin-right{
  padding-right: 8vw;
}

.bitcoin-center img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -45%);
  z-index: 1;
}

.elon-img{
  margin-top: -30px;
  z-index: 2;
}

.post1 {
  margin-left: -100px;
}

.bitcoin-section-container{
  padding: 0px 30px;
}

@media only screen and (min-width: 1920px) {
  .bitcoin-right{
    padding-right: 150px;
  }
}

@media only screen and (max-width: 1350px) {
  .bitcoin-right{
    padding-right: 0px;
  }

  .bitcoin-center img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -45%);
    z-index: 1;
  }
}

@media only screen and (max-width: 1250px) {
  .bitcoin-center img{
    width: 100%;
    max-width: 500px;
  }
}

@media only screen and (max-width: 1130px) {
  .bitcoin-row{
    flex-direction: column;
    gap: 30px;
  }

  .bitcoin-section{
    min-height: 226px;
    max-height: min-content;
    width: min-content;
    padding: 20px 30px;
  }

  .bitcoin-center img{
    width: 100%;
    max-width: 500px;
    transform: translate(-50%, -40%);
  }

  .elon-img{
    margin-top: 0px;
  }

}

@media only screen and (max-width: 786px) {
  .bitcoin-left{
    flex-direction: column;
  }

  .elon-img{
    margin-left: 10vw;
  }

  .post1{
    margin-left: 0px;
    margin-top: -70px;
  }

  .bitcoin-center img{
    position: static;
    width: 140%;
    max-width: 700px;
    transform: translate(-20%, -20%);
  }
  
}

@media only screen and (max-width: 500px) {
  .bitcoin-left{
    flex-direction: column;
  }

  .elon-img{
    margin-left: 10vw;
    /* width: 100%; */
  }

  .post1{
    margin-left: 0px;
    margin-top: -70px;
    /* width: 100%; */
  }

  .bitcoin-center img{
    position: static;
    width: 100%;
    
    max-width: 700px;
    transform: translate(-0%, 0%);
  }

  .bitcoin-center{
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .bitcoin-section{
    width: 100%;
  }

  .bitcoin-section-container{
    padding-left: 0px;
    padding-right: 0px;

  }
  
}