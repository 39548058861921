.crousel-buttons {
  border: none;
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  margin-left: 10px;
  border-radius: 50%;
  padding: 0px;
}
.cr-image {
  height: 50vh;
  margin: auto;
}
