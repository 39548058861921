.custom-accordion{
    background-color: #29415e;
    color: white;
}

.faq-heading{
    font-family: "Montserrat", sans-serif;
    font-size: 64px;
    color: white;
    text-align: center;
    font-weight: bold;
    padding-bottom: 32px;
}

.faq-section{
    padding-top: 120px;
    margin-bottom: 44px;
}

.faq-section .accordion-button:not(.collapsed){
    box-shadow: none;
}

.faq-section .accordion-button{
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding: 30px;
}

.faq-section .accordion-button::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='%23fff'%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.faq-section .accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='%23fff'%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.faq-section .accordion-body{
    background-color: rgb(41, 65, 94);
    color: white;
    font-family: "Montserrat", sans-serif !important;
    padding: 30px ;
    padding-top: 0px;
}

.faq-section .accordion-body a,
.faq-section .accordion-body p{
    font-family: "Montserrat", sans-serif !important;
    color: white !important;
    line-height: 40px !important;
}

.faq-section .accordion-body a{
    line-height: 20px !important;
    margin-bottom: 5px !important;
    display: inline-block;
}

.faq-section .accordion-body a{
    text-decoration: underline;
}


@media only screen and (max-width: 1350px) {
    .faq-section .col-9{
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 30px;
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .faq-section .accordion-button,
    .faq-section .accordion-body{
        font-size: 16px;
        padding: 20px;
    }

    .faq-section .accordion-body{
        padding-top: 0px;
        word-break: break-all;
    }

    .faq-heading{
        font-size: 48px;
    }
}

@media only screen and (max-width: 500px) {
    .faq-section .accordion-button,
    .faq-section .accordion-body{
        font-size: 16px;
        padding: 20px;
    }

    .faq-section .accordion-body{
        padding-top: 0px;
        font-size: 14px;
        word-break: break-all;
    }

    .faq-heading{
        font-size: 48px;
    }

    .faq-section .accordion-body a,
.faq-section .accordion-body p{
    line-height: 22px;
}
}