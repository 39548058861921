.news-container {
  background-color: black;
  margin: 0;
  padding: 60px 20px;
}

.heading-gredient {
  background-clip: text;
  background: linear-gradient(to right, #f2842f, #ffce29);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.news-heading {
  font-weight: bold;
  margin-top: 20px;
  font-size: 90px;
}

.news-image {
  width: 70%;
}

.news-text{
  max-width: 1240px;
  margin: 0 auto;
  font-family: "ChronicaPro", sans-serif;
  font-size: 18px;
  line-height: 40px;
  margin-bottom: 50px;
}

.news-grid{
  max-width: 916px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  row-gap: 64px;
  column-gap: 40px;
}

.news-grid-item a{
  height: 100%;
  display: block;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-12{
  height: 40px;
}

.h-8{
  height: 32px;
}

.h-14{
  height: 56px;
}




@media only screen and (max-width: 1150px) {
  .news-heading{
    font-size: 60px;
  }

  .news-text{
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 786px) {
  .news-heading{
    font-size: 42px;
  }

  /* .news-text{
    font-size: 16px;
    line-height: 30px;
  } */
}

@media screen and (max-width: 500px) {
  .news-image {
    width: 90%;
  }
}
