@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;900&display=swap');

@font-face {
  font-family: "ChronicaPro";
  font-weight: 900;
  src: local("ChronicaPro"),
    url(./Components/fonts/ChronicaPro-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "ChronicaPro";
  font-weight: 400;
  src: local("ChronicaPro"),
    url(./Components/fonts/ChronicaPro-regular-ok.ttf) format("truetype");
}

@font-face {
  font-family: "chronicalight";
  font-weight: 900;
  src: local("chronicalight"),
    url(./Components/fonts/ChronicaPro-Medium.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "ChronicaPro", Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #112B4C;
}

*{
  font-family: "ChronicaPro";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
