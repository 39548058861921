a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
}

.accordion a {
  color: #007bff !important;
}

.text-desc {
  text-align: justify;
  font-weight: normal;
  font-style: italic;
  font-size: 15;
  line-height: 20pt;
  font-family: chronicalight;
  color: #9ba4b0;
}

.btn-common{
  height: 2.5rem;
  border-radius: 0.375rem;
  background-image: linear-gradient(to right,var(--tw-gradient-stops));
  --tw-gradient-from: #FFCC00;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to, rgba(255, 204, 0, 0));
  --tw-gradient-to: #E64656;
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  font-family: Montserrat;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 600;
  line-height: .875rem;
  --tw-text-opacity: 1;
  color: rgba(0,0,0,var(--tw-text-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
  color: black  !important;
}

.heading-sec{
  font-size: 50px;
}
