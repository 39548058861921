footer {
  background-color: black;
}

.footer-container{
  max-width: 1400px !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 20px;
}


.footer-heading {
  font-weight: bolder;
  background-clip: text;
  background: linear-gradient(to right, #f2842f, #ffce29);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-left p{
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  padding-top: 21px;
  font-weight: 600;
}

.footer-right{
  display: flex;
  align-items: flex-start;
  width: 35rem;
  justify-content: flex-end;
  padding-right: 30px;
  gap: 100px;
}

.footer-right h3{
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 18px;
  padding-bottom: 30px;
}

.footer-right p a{
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 16px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1500px) {
  footer{
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .footer-container{
    flex-direction: column;
  }

  .footer-right{
    width: auto;
    margin-top: 50px;
    gap: 9vw;
    flex-wrap: wrap;
  }

  .footer-left{
    text-align: center;
  }

  .footer-left img{
    width: 90%;
    margin: 0 auto;
    
  }
}