.header {
  height: 100px;
  background-color: black;
  padding: 0px !important;
}

.header-button {
  background-image: linear-gradient(to right, #ffcc00, #e64656);
  outline: none;
  border: none;
}

.header-container{
  padding: 0px 30px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-left{
  display: flex;
  align-items: center;
}

.header-right{
  display: flex;
  align-items: center;
  gap: 30px;
}

.header-right a{
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: white !important;
}

.header-right .header-btn{
  height: 2.5rem;
  border-radius: 0.375rem;
  background-image: linear-gradient(to right,var(--tw-gradient-stops));
  --tw-gradient-from: #FFCC00;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to, rgba(255, 204, 0, 0));
  --tw-gradient-to: #E64656;
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  font-family: Montserrat;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 600;
  line-height: .875rem;
  --tw-text-opacity: 1;
  color: rgba(0,0,0,var(--tw-text-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
  color: black  !important;
  transition: all .4s ease-in-out;
}


.header-right .header-btn:hover{
  background-image: linear-gradient(to left,var(--tw-gradient-stops));
  --tw-gradient-from: #FFCC00;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to, rgba(255, 204, 0, 0));
  --tw-gradient-to: #E64656;
  background-position: top;
}

.navbar-brand{
  padding: 0px !important;
}

.header-social-links{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.header-right .header-social-links{
  display: none;
}

.header-divider {
  height: 40px;
  width: 2px;
  background-image: linear-gradient(#ffcc00, #e64656);
  margin-right: 15px;
}

.menu-toggler {
  border: none;
  background: none;
  display: none;
}

.menu-toggler svg{
  width: 32px;
  height: 32px;
  color: white;
}

.body {
  background: linear-gradient(#0b1b2f 0%, #0b1b2f 8.48%, #112b4c 15.27%, #112b4c 100%);
}

.logo {
  width: 300px;
}

@media screen and (max-width: 1024px) {
  .menu-toggler {
    display: block;
  }

  .header-right{
    position: absolute;
    background: black;
    width: 100%;
    top: 100%;
    left: 0;
    padding: 30px;
    flex-direction: column;
    transform: translateX(100%);
    transition: all .3s ease-in-out;
  }

  .header-right.active{
    transform: translateX(0%);
  }
}

@media screen and (max-width: 600px) {
  .header-social-links {
    display: none;
  }

  .header-right .header-social-links{
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 250px;
    height: 100px;
  }
}

@media screen and (max-width: 350px) {
  .logo {
    width: 200px;
    height: 100px;
  }
}
